<template>
  <div>
    <el-select
      class="item"
      :value="provinceCode?provinceCode:''"
      :placeholder="$l('address.province','省份')"
      clearable
      @change="onProvinceChange">
      <el-option
        v-for="item in provinces"
        :key="item.id"
        :label="item.label"
        :value="item.id"></el-option>
    </el-select>
    <el-select
      class="item"
      style="margin: 0 5px"
      :value="cityCode?cityCode:''"
      :placeholder="$l('address.city','城市')"
      clearable
      @change="onCityChange">
      <el-option
        v-for="item in cities"
        :key="item.id"
        :label="item.label"
        :value="item.id"></el-option>
    </el-select>
  </div>
</template>

<script>
  import {mutations, store} from "@/store/store";

  export default {
    components: {},
    props: {
      provinceCode: String,
      cityCode: String,
    },
    data() {
      return {};
    },
    computed: {
      provinces() {
        return store.district;
      },
      cities() {
        return this.$oc(() => {
          let item = this.provinces.find(item => {
            return item.id === this.provinceCode;
          });
          return item.children;
        });
      },
    },
    mounted() {
      mutations.getDistrict().then(() => {
        console.log("----获取地区成功----");
      });
    },
    methods: {
      onProvinceChange(provinceCode) {
        this.$emit("update:provinceCode", provinceCode);
        this.$emit("update:cityCode", "");
      },
      onCityChange(cityCode) {
        this.$emit("update:cityCode", cityCode);
      },
    },
  };
</script>

<style lang="scss" scoped>
.item {
  width: 150px;
}
</style>
