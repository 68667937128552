import Vue from "vue";
import http from "@/plugins/http/http";

export let store = Vue.observable(
  {
    dicts: null,
    monitorDicts: null,
    district: null,
  });

export let mutations = {
  getDicts() {
    return new Promise((resolve, reject) => {
      if (store.dicts) {
        resolve(store.dicts);
      } else {
        http.get("basic/dict/from-enum").then(data => {
          store.dicts = data;
          resolve(data);
        }).catch(e => {
          reject(e);
        });
      }
    });
  },
  getMonitorDicts() {
    return new Promise((resolve, reject) => {
      if (store.monitorDicts) {
        resolve(store.monitorDicts);
      } else {
        http.get("basic/dict/from-map").then(data => {
          store.monitorDicts = data;
          resolve(data);
        }).catch(e => {
          reject(e);
        });
      }
    });
  },
  getDistrict() {
    return new Promise((resolve, reject) => {
      if (store.district) {
        resolve(store.district);
      } else {
        http.get("basic/district/tree").then(data => {
          store.district = data;
          resolve(data);
        }).catch(e => {
          reject(e);
        });
      }
    });
  },
};
