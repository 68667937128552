export default {
  genKey() {
    return this.randomString(8);
  },
  randomString(e) {
    e = e || 32;
    let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
    let a = t.length;
    let n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n;
  },
  //上移
  up(arr, index) {
    if (index === 0) {
      return;
    }
    arr[index] = arr.splice(index - 1, 1, arr[index])[0];
  },
  //下移
  down(arr, index) {
    if (index === arr.length - 1) {
      return;
    }
    arr[index] = arr.splice(index + 1, 1, arr[index])[0];
  },
  orderStatus(status) {
    console.log(status,28);
    let orderStatus = 0;
    switch (status) {
      case "to_be_assigned":
          orderStatus=1;
          break;
      case "assigned":
        orderStatus=2;
        break;

      case "repairing":
        orderStatus=3;
        break;
      case "maintaining":
        orderStatus=3;
        break;
      case "completed":
        orderStatus=4;
        break;
      case "customer_completed":
        orderStatus=5;
        break;
      case "approved":
        orderStatus=6;
        break;
      case "cancelled":
        orderStatus=7;
        break;

      default:
        orderStatus=0;
    }
    return orderStatus;

  },
};
