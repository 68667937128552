export default {
  common: {
    search: "Search",
    reset: "Reset",
    save: "Save",
    cancel: "Cancel",
    add: "Add",
    function: "Function",
    tip: "Tip",
    edit: "Edit",
    delete: "Delete",
    enter: "Please enter",
    select: "Please select",
    loading: "Loading",
    basicInfo: "Basic information",
    locationInfo: "Location information",
    loadFailTip: "Load failed, click to refresh",
    noDataTip: "No data yet, click to refresh",
    selectDate: "Select date",
    addressSearch: "Address search",
    latLng: "Current latitude and longitude",
    selectLoc: "Click on the map to select a location",
    version: "Version",
    lastHeart: "Last heartbeat time",
    createTime: "Create time",
    updateTime: "Update time",
    createBy: "Creator",
    updateBy: "Modifier",
    operator: "Operator",
    operatingTime: "Operating time",
    deleteTip: "Are you sure to delete {key}?",
    deleteSuccessTip: " delete Success",
    connecting: "Connecting",
    status: "Status",
    note: "Note",
    upload: "Upload",
    sure: "Sure",
    download: "Download",
    goback: "goback",
  },
  rule: {
    required: "Required",//不能为空
    number: "Must be a number",//必须为数字
  },
  moduleName: {
    home: "Home",//首页
    elevator: "Elevator info",//电梯档案
    elevatorMonitor: "Elevator monitor",//电梯监控
    company: "Company info",//门店信息
    project: "Project info",//项目信息
    device: "Device info",//终端信息
    theme: "Device theme",//屏幕主题信息
    news: "News list",//屏幕主题信息
    customer: "Customer info",//客户信息
    elevatorFault: "Elevator fault",//故障记录
    maintenance: "Maintenance info",//维保记录
    firmware: "Firmware info",//固件版本
    elevatorMap: "Elevator map",//电梯分布
    system: "System",//系统管理
    user: "User info",//用户信息
    role: "Role info",//角色信息
    operationLog: "System log",//系统日志
    messageSend: "Push message",//消息发送
    globalValue: "Global value",//全局变量
    wbTemplate: "Maintain template",//维保模板
    bigScreen: "Big Screen", //大屏展示
    elevatorDetail: "elevator detail", //电梯信息详情
    inspection: "inspection", //巡检
  },
  address: {
    address: "Address",
    area: "Area",
    province: "Province",
    city: "City",
    district: "District",
  },
  user: {
    labelWidth: "120px",
    contactPerson: "Contact person",
    contactNumber: "Contact number",
    phone: "Phone number",
    edit: "Edit user",
    add: "Add user",
    name: "Name",
    type: "User type",
    status: "User status",
    resetPassword: "Reset password",
    role: "User role",
  },
  login: {
    welcome: "Welcome to ETC Platform",
    username: "Username",
    password: "Password",
    login: "Login",
  },
  main: {
    logout: "Logout",
    changePassword: "Change password",
    logoutTip: "Are you sure to logout?",
    oldPassword: "Old password",
    newPassword: "New password",
  },
  elevator: {
    labelWidth: "160px",
    elevators: "Elevators",
    elevator: "Elevator",
    select: "Select elevator",
    no: "Elevator No.",
    name: "Elevator name",
    noTip: "If empty, the No. will be generated",
    edit: "Edit elevator",
    add: "Add elevator",
    floorInfo: "Floor information",
    load: "Load(kg)",
    speed: "Speed(m/s)",
    floorStation: "Floor/Station",
    doorOpenType: "Door opening type",
    doorOpenType1: "Automatic Door",
    doorOpenType2: "Handle door",
    doorOpenWidth: "Door opening width",
    deliveryDate: "Delivery date",
    floorLabelWidth: "250px",
    floor1: "Physical floor 1 button identification",
    floor2: "Physical floor 2 button identification",
    floor3: "Physical floor 3 button identification",
    floor4: "Physical floor 4 button identification",
    floor5: "Physical floor 5 button identification",
    floor6: "Physical floor 6 button identification",
  },
  monitor: {
    monitor: "Monitor",
    call: "Call elevator",
    stop: "Stop elevator",
    lightCtrl: "Light control",
    back: "Back to Elevators",
    basicStatus: "Basic status",
    otherStatus: "Other status",
    deviceOffline: "Elevator offline",
    waitingData: "Waiting data",
    controlSystem: "Control system info",
  },
  wb: {
    labelWidth: "175px",
    wbInfo: "Maintenance information",
    worker: "Maintenance worker",
    add: "Add maintenance plan",
    edit: "Edit maintenance record",
    no: "Work order number",
    planDate: "Plan date",
    intend: "Intend",
    completed: "Completed",
    dueToday: "Due today",
    overdue: "Overdue",
    picture: "Picture",
    addTip: "Each elevator only needs to add a maintenance plan once, and the subsequent maintenance plan will be automatically generated according to the maintenance cycle after the maintenance is completed, or it can be added manually.",
    selectWorkerTip: "Use elevator worker or modify",
    type: "Maintenance type",
    typeRoutine: "Routine maintenance",
    typeComponent: "Component replacement",
  },
  device: {
    labelWidth: "130px",
    no: "Device No.",
    edit: "Edit device",
    add: "Add device",
    simCardNo: "SIM card number",
    upgrade: "Upgrade",
    model: "Device Model",
  },
  customer: {
    labelWidth: "130px",
    name: "Customer name",
    edit: "Edit customer",
    add: "Add customer",
  },
  company: {
    labelWidth: "130px",
    name: "Company name",
    edit: "Edit company",
    add: "Add company",
  },
  project: {
    labelWidth: "140px",
    name: "Project name",
    edit: "Edit project",
    add: "Add project",
  },
  firmware: {
    labelWidth: "130px",
    edit: "Edit firmware",
    add: "Add firmware",
    name: "Firmware name",
    version: "Firmware version",
    upload: "Upload firmware",
  },
  theme: {
    labelWidth: "130px",
    themeFile: "Theme file",
    add: "Add theme",
    name: "Theme name",
    previewImage: "Preview image",
    downloadUrl: "DownLoad url",
  },
  fault: {
    type: "Fault type",
    code: "Fault code",
  },
  role: {
    edit: "Edit role",
    add: "Add role",
    name: "Role name",
    en: "labelEn",
    permissionWidth: "230px",
  },
  operationLog: {
    module: "Module",
    content: "Content",
  },
  messageSend: {
    receiver: "Receiver",
    content: "Message content",
    isRead: "Has been read",
    yes: "Read",
    no: "Unread",
  },
  globalValue: {
    key: "Key",
    value: "Value",
    en: "nameEn",
  },
  news: {
    edit: "Edit news",
    add: "Add news",
    title: "Title",
    content: "Content",
  },
};
