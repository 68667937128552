<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

  export default {
    data() {
      return {};
    },
    mounted() {
      // 通过监听hashchange事件 判断当前页面的路由与实际的路由是否一致
      window.addEventListener("hashchange", () => {
        let currPath = window.location.hash.slice(1);
        if (this.$route.path !== currPath) { // 不一致就跳转url的路由
          this.$router.push(currPath);
        }
      }, false);
    },
  };
</script>
<style lang="scss">
  #app {
    height: 100vh;
  }
</style>
