import Vue from "vue";
import VueRouter from "vue-router";
import loginUtil from "@/util/loginUtil";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import {myI18n} from "@/i18n";

Vue.use(VueRouter);
//安规
const convention=[ {path: "/convention/elevatorlist", meta: {title: "电梯列表"}, component: () => import("@/views/elevator/convention/index")},
    {path: "/convention/maintainorder", meta: {title: "保养工单"}, component: () => import("@/views/maintenance/maintenanceRule/maintenanceOrder/maintenanceNew")},
    {path: "/convention/maintainplan", meta: {title: "保养计划"}, component: () => import("@/views/maintenance/maintenanceRule/maintenancePlan/MaintainPlan")},

];

const routes = [
  {
    path: "/",
    meta: {title: "Main中展示的组件"},
    component: () => import("@/views/main/Main"),
    children: [
      {path: "/", meta: {title: myI18n("moduleName.home", "首页")}, component: () => import("@/views/main/Home")},
      {path: "/user/UserList", meta: {title: myI18n("moduleName.user", "用户信息")}, component: () => import("@/views/user/UserList")},
      {path: "/role/RoleList", meta: {title: myI18n("moduleName.role", "角色信息")}, component: () => import("@/views/role/RoleList")},
      {path: "/log/logs", meta: {title: myI18n("moduleName.logs", "登录日志")}, component: () => import("@/views/logs/Logs")},
      {path: "/log/operatelogs", meta: {title: myI18n("moduleName.logs", "操作日志")}, component: () => import("@/views/logs/OperateLogs")},
      {path: "/operationLog/OperationLogList", meta: {title: myI18n("moduleName.operationLog", "系统日志")}, component: () => import("@/views/operationLog/OperationLogList")},
      {path: "/globalValue/ValueList", meta: {title: myI18n("moduleName.globalValue", "全局变量")}, component: () => import("@/views/globalValue/ValueList")},
      {path: "/wbTemplate/List", meta: {title: myI18n("moduleName.wbTemplate", "维保模板")}, component: () => import("@/views/wbTemplate/List")},
      {path: "/wbTemplate/DetailList", meta: {title: myI18n("moduleName.wbTemplate", "维保模板详情")}, component: () => import("@/views/wbTemplate/DetailList")},
      {path: "/eventTemplate/List", meta: {title: myI18n("moduleName.eventTemplate", "事件模板")}, component: () => import("@/views/eventTemplate/List")},
      {path: "/messageSend/MessageSendList", meta: {title: myI18n("moduleName.messageSend", "消息发送")}, component: () => import("@/views/messageSend/MessageSendList")},
      {path: "/customer/CustomerList", meta: {title: myI18n("moduleName.customer", "客户信息")}, component: () => import("@/views/customer/CustomerList")},
      {path: "/elevator/ElevatorList", meta: {title: myI18n("moduleName.elevator", "电梯档案")}, component: () => import("@/views/elevator/index")},
      {path: "/elevator/customerelevatorList", meta: {title: myI18n("moduleName.elevator", "电梯档案")}, component: () => import("@/views/customerFunction/index")},
      {path: "/elevator/Elevatordetail", meta: {title: myI18n("moduleName.elevatorDetail", "电梯详情")}, component: () => import("@/views/elevator/ElevatorDetail")},
      {path: "/elevator/Elevatormapnew", meta: {title: myI18n("moduleName.elevatorMap", "新电梯分布")}, component: () => import("@/views/elevator/ElevatorMapNew")},
      {path: "/device/List", meta: {title: myI18n("moduleName.device", "终端信息")}, component: () => import("@/views/device/List")},
      {path: "/news/NewsList", meta: {title: myI18n("moduleName.news", "新闻列表")}, component: () => import("@/views/news/NewsList")},
      {path: "/project/ProjectList", meta: {title: myI18n("moduleName.project", "项目信息")}, component: () => import("@/views/project/ProjectList")},
      {path: "/contract/list", meta: {title: myI18n("moduleName.project", "合同信息")}, component: () => import("@/views/contract/ContractList")},
      {path: "/company/List", meta: {title: myI18n("moduleName.company", "企业信息")}, component: () => import("@/views/company/List")},
      {path: "/wb/WbList", meta: {title: myI18n("moduleName.maintenance", "电梯维保")}, component: () => import("@/views/wb/WbList")},
      {path: "/repair/list", meta: {title: myI18n("moduleName.repair", "电梯维修")}, component: () => import("@/views/repair/RepairList")},
      {path: "/inspection/list", meta: {title: myI18n("moduleName.inspection", "电梯巡检")}, component: () => import("@/views/inspection/InspectionList")},
      {path: "/fault/list", meta: {title: myI18n("moduleName.fault", "故障记录")}, component: () => import("@/views/event/FaultList")},
      {path: "/event/list", meta: {title: myI18n("moduleName.event", "事件记录")}, component: () => import("@/views/event/EventList")},
       {path: "/elevator-using-company/page", meta: {title: "客户公司"}, component: () => import("@/views/elevatorUsingCompany/List")},
       {path: "/elevator-using-customer/page", meta: {title: "客户人员"}, component: () => import("@/views/elevatorUsingCustomer/List")},
       {path: "/using-customer/page", meta: {title: "客户人员"}, component: () => import("@/views/elevatorUsingCustomer/CustomerList")},
       {path: "/elevator-customer/page", meta: {title: "客户人员"}, component: () => import("@/views/elevatorUsingCompany/CompanyAndCustomer")},
       {path: "/elevator-using-realestate/page", meta: {title: "客户楼盘"}, component: () => import("@/views/elevatorUsingRealEstate/RealEstateAndBuilding")},
       {path: "/elevator-using-repair/page", meta: {title: "客户统计"}, component: () => import("@/views/customerFunction/dataStatistics/repairElevator.vue")},
       {path: "/framework/company", meta: {title: "公司管理"}, component: () => import("@/views/framework/Company")},
       {path: "/framework/department", meta: {title: "部门管理"}, component: () => import("@/views/framework/Department")},
       {path: "/framework/team", meta: {title: "班组管理"}, component: () => import("@/views/framework/team")},
       {path: "/framework/post", meta: {title: "岗位管理"}, component: () => import("@/views/framework/Post")},
       {path: "/framework/employee", meta: {title: "岗位管理"}, component: () => import("@/views/framework/Employee")},
       {path: "/basic/district", meta: {title: "省市区"}, component: () => import("@/views/basic/District")},
       {path: "/basic/permissions", meta: {title: "权限树"}, component: () => import("@/views/basic/Permissions")},
       {path: "/basic/modal", meta: {title: "电梯品牌"}, component: () => import("@/views/basic/BrandAndModal")},
       {path: "/basic/fault", meta: {title: "故障信息"}, component: () => import("@/views/basic/Fault")},
       {path: "/basic/Maintenance", meta: {title: "保养信息"}, component: () => import("@/views/basic/Maintenance")},
       {path: "/basic/accessory", meta: {title: "电梯配件管理"}, component: () => import("@/views/basic/AccessoriesIndex")},
       {path: "/basic/routing-inspection", meta: {title: "电梯巡检字典"}, component: () => import("@/views/basic/RoutingInspection")},
       {path: "/basic/appearance", meta: {title: "电梯故障现象管理"}, component: () => import("@/views/basic/Appearance")},
       {path: "/basic/banner", meta: {title: "图片管理"}, component: () => import("@/views/basic/Banner")},
       {path: "/maintainplan/maintainplan", meta: {title: "保养计划"}, component: () => import("@/views/maintenance/maintenance/maintenancePlan/MaintainPlan")},
       {path: "/maintainplan/customermaintainplan", meta: {title: "保养计划"}, component: () => import("@/views/customerFunction/maintenance/maintenancePlan/MaintainPlan")},
       {path: "/maintainplan/calendar", meta: {title: "保养计划日历"}, component: () => import("@/views/maintenance/Calendar")},
       {path: "/maintainplan/calendarrule", meta: {title: "保养计划日历"}, component: () => import("@/views/maintenance/maintenanceRule/Calendar")},
       {path: "/maintainplan/maintainorder", meta: {title: "保养工单"}, component: () => import("@/views/maintenance/maintenance/maintenanceOrder/maintenanceNew")},
       {path: "/maintainplan/customermaintainorder", meta: {title: "保养工单"}, component: () => import("@/views/customerFunction/maintenance/maintenanceOrder/maintenanceNew")},
       {path: "/maintainplan/reportrepair", meta: {title: "报修管理"}, component: () => import("@/views/maintenance/repair/repairPlan/reportRepairNew")},
       {path: "/maintainplan/reportrepairrule", meta: {title: "报修管理"}, component: () => import("@/views/maintenance/repairRule/repairPlan/reportRepairNew")},
       {path: "/maintainplan/customerreportrepair", meta: {title: "报修管理"}, component: () => import("@/views/customerFunction/repair/repairPlan/reportRepairNew")},
       {path: "/maintainplan/customertel", meta: {title: "客服报修"}, component: () => import("@/views/maintenance/repair/repairPlan/CustomerTele")},
       {path: "/maintainplan/customertelorder", meta: {title: "客服报修工单"}, component: () => import("@/views/maintenance/repair/repairPlan/CustomerTeleOrder.vue")},
       {path: "/maintainplan/notice", meta: {title: "工单超时通知配置"}, component: () => import("@/views/maintenance/Notice")},
       {path: "/maintainplan/repairorder", meta: {title: "维修工单管理"}, component: () => import("@/views/maintenance/repair/reportOrder/RepairOrderNew")},
       {path: "/maintainplan/repairorderrule", meta: {title: "维修工单管理"}, component: () => import("@/views/maintenance/repairRule/reportOrder/RepairOrderNew")},
       {path: "/maintainplan/customerrepairorder", meta: {title: "维修工单管理"}, component: () => import("@/views/customerFunction/repair/reportOrder/RepairOrderNew")},
       // {path: "/inspection/list", meta: {title: "维修工单管理"}, component: () => import("@/views/customerFunction/repair/reportOrder/RepairOrderNew")},
       {path: "/maintainplan/repairorderdetail", meta: {title: "维修工单详情"}, component: () => import("@/views/maintenance/repair/reportOrder/RepairDetailNew")},
       {path: "/maintainplan/maintanceorderdetail", meta: {title: "维修工单详情"}, component: () => import("@/views/maintenance/maintenance/maintenanceOrder/MaintenanceDetailNew")},
       {path: "/maintainplan/maintenanceOrderConfig", meta: {title: "保养工单生成与超期规则配置"}, component: () => import("@/views/maintenance/maintenance/maintenanceOrderConfig")},
       {path: "/maintainplan/repairprint", meta: {title: "打印"}, component: () => import("@/views/maintenance/repair/reportOrder/RepairPrint")},
       {path: "/maintainplan/halfmonth", meta: {title: "打印"}, component: () => import("@/views/maintenance/maintenance/maintenanceOrder/HalfMonthPrint")},
       {path: "/maintainplan/config", meta: {title: "按需维保规则配置"}, component: () => import("@/views/maintenance/MaintenanceConfig")},
       {path: "/elevator/tabledraw", meta: {title: "按需维保规则配置"}, component: () => import("@/views/elevator/tableDraw")},
       {path: "/datastatistics/elevatoronline", meta: {title: "电梯在线率统计"}, component: () => import("@/views/dataStatistics/ElevatorOnlineList")},
       {path: "/datastatistics/elevatoroffline", meta: {title: "离线电梯"}, component: () => import("@/views/dataStatistics/ElevatorOnlineDetail")},
       {path: "/datastatistics/repairstatistics", meta: {title: "维修统计"}, component: () => import("@/views/dataStatistics/RepairStatisticsList")},
       {path: "/datastatistics/maintainstatistics", meta: {title: "保养统计"}, component: () => import("@/views/dataStatistics/MaintainStatisticsList")},
       {path: "/datastatistics/faultalarm", meta: {title: "故障报警统计"}, component: () => import("@/views/dataStatistics/FaultAlarmList")},
       {path: "/datastatistics/elevatoronlinecount", meta: {title: "电梯在线离线数量统计"}, component: () => import("@/views/dataStatistics/ElevatorOnlineCountList")},
        {path: "/datastatistics/iotdisabled", meta: {title: "电梯禁用列表"}, component: () => import("@/views/dataStatistics/IotDisabled")},
        {path: "/datastatistics/faultorder", meta: {title: "电梯维修工单统计"}, component: () => import("@/views/dataStatistics/ElevatorFaults")},
        {path: "/datastatistics/serviceinterval", meta: {title: "电梯保养间隔统计"}, component: () => import("@/views/dataStatistics/ServiceInterval")},
        {path: "/datastatistics/rescuetime", meta: {title: "电梯维修时间"}, component: () => import("@/views/dataStatistics/RescueTime")},
        {path: "/datastatistics/faultrate", meta: {title: "维保统计指标"}, component: () => import("@/views/dataStatistics/FaultRate")},
        {path: "/datastatistics/hospital", meta: {title: "维保统计指标"}, component: () => import("@/views/dataStatistics/elevatorHospital.vue")},
        {path: "/datastatistics/warning", meta: {title: "维保统计指标"}, component: () => import("@/views/dataStatistics/faultWarning.vue")},
        {path: "/datastatistics/maintainChecklist", meta: {title: "保养清单统计"}, component: () => import("@/views/dataStatistics/MaintainChecklist.vue")},
        {path: "/system/info", meta: {title: "系统消息推送"}, component: () => import("@/views/system/SystemInfo")},
        {path: "/system/systeminfo", meta: {title: "系统消息推送"}, component: () => import("@/views/system/index")},
        {path: "/system/pass", meta: {title: "96333配置"}, component: () => import("@/views/company/Password.vue")},
    ].concat(convention),
  },
  {path: "/login", meta: {title: "登录"}, component: () => import("@/views/main/Login")},
  {path: "/download", meta: {title: "下载"}, component: () => import("@/views/main/downloads")},
  {path: "/Elevatormap", meta: {title: myI18n("moduleName.elevatorMap", "电梯分布")}, component: () => import("@/views/elevator/ElevatorMap")},
  {path: "/Elevatormapcustomer", meta: {title: myI18n("moduleName.elevatorMap", "电梯分布")}, component: () => import("@/views/customerFunction/ElevatorMap")},
  {path: "/monitor/LiftMonitor", meta: {title: myI18n("moduleName.elevatorMonitor", "单梯监控")}, component: () => import("@/views/monitor/LiftMonitor")},
  {path: "/monitor/LiftMonitordetail", meta: {title: myI18n("moduleName.elevatorMonitor", "单梯监控")}, component: () => import("@/views/monitor/LiftMonitorDetail")},
  {path: "/bigscreen/index", meta: {title:  "大屏展示"}, component: () => import("@/views/template/bigscreen3")},
  {path: "/bigscreen/index1", meta: {title:  "大屏展示"}, component: () => import("@/views/template/bigscreen1")},
  {path: "/bigscreen/index2", meta: {title:  "大屏展示"}, component: () => import("@/views/template/bigscreen4")},
  {path: "/template/index", meta: {title:  "大屏模板"}, component: () => import("@/views/template/bigscreen")},
  {path: "/template/faultratee", meta: {title: "电梯故障率统计"}, component: () => import("@/views/elevatorUsingRealEstate/ReportDetail")},
  {path: "/templateSecond/index", meta: {title:  "大屏模板"}, component: () => import("@/views/template/bigscreenSecond")},
  {path: "/index/index", meta: {title:  "大屏模板"}, component: () => import("@/views/index/index")},
  {path: "/index/mqtt", meta: {title:  "大屏模板"}, component: () => import("@/views/monitor/simulationMontior")},
];

const router = new VueRouter({
  routes,
});

const whiteUris = ["/login", "/download"]; // 白名单,无需登录即可访问

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (whiteUris.indexOf(to.path) !== -1 || loginUtil.isLogin()) {
    if (to.meta.title) {
      document.title = to.meta.title;
    } else {
      document.title = "xx电梯";
    }
    // 在白名单当中或者已经登录,放行
    next();
  } else {
    toLogin(to.fullPath);
  }
});

router.afterEach(() => {
  NProgress.done();
});

export const toLogin = () => {
  router.push({
    path: "/login",
  });
};

export default router;
