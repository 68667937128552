//校验规则
import {myI18n} from "@/i18n";

export default {
  notNull: {required: true, message: myI18n("rule.required", "不能为空"), trigger: ["change", "blur"]},
  number: {message: myI18n("rule.number", "必须为数字"), pattern: /^(0|\+?[1-9][0-9]*)$/},
  float2: {message: "最多2位小数点的数字", pattern: /^-?\d{1,20}(?:\.\d{1,2})?$/},
  phone: {message: "1开头的11位数字", pattern: /^1[0-9]{10}$/},
  username: {message: "4~25位字符，只能包含英文字母、数字、@、点、下划线", pattern: /^[a-zA-Z0-9_@.]{4,25}$/},
  password: {message: "6~20位,不能为纯数字或者纯字母", pattern: /^(?=.*\d)(?=.*[A-Za-z])[\x20-\x7e]{6,20}$/},
};
