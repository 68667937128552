<!--带有分页逻辑的table-->
<template>
  <div class="vm-page-table">
    <div
      v-if="$slots.adSearch"
      ref="adSearch"
      class="ad-search">
      <div id="search" class="ad-search_content">
        <slot name="adSearch"></slot>
        <div class="ad-search-btn">
          <slot name="btn"></slot>
          <el-button
            v-if="!hideClear"
            plain
            :disabled="status==='loading'"
            size="mini"
            style="height: 26px;padding: 0 10px"
            type="primary"
            @click="handleClearClick">
            {{$l("common.reset", "重置")}}
          </el-button>
          <el-button
            :disabled="status==='loading'"
            size="mini"
            style="height: 26px;padding: 0 10px"
            type="primary"
            @click="handleSearchClick">
            {{$l("common.search", "查询")}}
          </el-button>
        </div>
      </div>
    </div>
    <div
      v-if="$slots.toolbar"
      class="tools-bar">
      <slot name="toolbar"></slot>
    </div>
    <div
      v-if="$slots.alert"
      class="tools-bar"
    >
      <slot name="alert"></slot>
    </div>
    <div class="wrapper">
      <div style="display: flex">
        <slot name="tree"></slot>
        <el-table
          ref="elTable"
          :data="tableData"
          border
          stripe
          :style="style"
          v-bind="$attrs"
          v-on="$listeners">
          <template slot="empty">
            <i v-if="status==='loading'" class="el-icon-loading" style="font-size: 18px"></i>
            <el-button v-else-if="status==='error'" @click="handleSearchClick">{{$l("common.loadFailTip", "加载失败，点击刷新")}}</el-button>
            <el-button v-else-if="status==='empty'" @click="handleSearchClick">{{$l("common.noDataTip", "暂无数据，点击刷新")}}</el-button>
          </template>

          <el-table-column
            v-if="multiSelection"
            type="selection"
            width="55"></el-table-column>
          <el-table-column v-if="showRadio" align="center" width="50">
            <template slot-scope="scope">
              <el-checkbox
                :value="scope.$index===currentRowIndex"
                @change="onCheckedChange($event,scope.$index)"></el-checkbox>
            </template>
          </el-table-column>
          <slot></slot>
        </el-table>
      </div>

      <div style="margin-top: 16px;overflow: hidden">
        <el-pagination
          v-if="paginationStatus"
          background
          :current-page="currentPage"
          :total="total"
          :page-size="pageSize"
          class="page"
          :page-sizes="[10, 20, 30, 50, 100, 200]"
          layout="sizes, total, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"></el-pagination>
      </div>
    </div>
    <el-button
      v-if="status==='loading'"
      type="warning"
      icon="el-icon-loading"
      class="loading">
      {{$l("common.loading", "加载中")}}
    </el-button>
  </div>
</template>

<script>
  /*查询条件filter必须使用:filter.sync="filter"传递*/
  export default {
    components: {},
    props: {
      url: {
        type: String,
        required: true,
      },
      filter: {
        type: Object,
      },
      filterFun: {
        type: Function,
      },
      //是否显示清空按钮
      hideClear: {
        type: Boolean,
        default: false,
      },
      multiSelection: {
        type: Boolean,
        default: false,
      },
      showRadio: {
        type: Boolean,
        default: false,
      },
      post: {
        type: Boolean,
        default: false,
      },
      paginationStatus: {
        type: Boolean,
        default: true,
      },
      senior:{
        type:Boolean,
        default:false,
      },
      immediately:{
        type:Boolean,
        default:false,
      },
      style:{
        type:Object,
        default:function() {
          return {};
        },
      },
      paginationPageSize:{
        type:Number,
        default:10,
      },
    },
    data() {
      return {
        status: "loading",//loading error empty
        getListType: 1,//1：自动获取，2查询获取，3重置获取
        currentPage: 1,
        pageSize: 0,
        total: 0,
        tableData: [],
        //保存一份filter的原始拷贝，用于重置条件
        filterCopy: JSON.stringify(this.filter),
        currentRowIndex: -1,
      };
    },
    mounted() {
      //为所有input框添加enter键搜索事件

      if (this.immediately) {
        this.getList(1);
      }

      let searchBox = document.getElementById("search");

      if (searchBox) {
        let controls = searchBox.getElementsByTagName("input");
        for (let item of controls) {
          item.onkeydown = event => {
            if (event.key === "Enter") {
              this.getList(1);
            }
          };
        }
      }
    },
    methods: {
      //等于-1表示获取当前页
      getList(pageNo,code={}) {
        this.status = "loading";
        this.pageSize=this.pageSize?this.pageSize:this.paginationPageSize;
        if (pageNo > 0) {
          this.currentPage = pageNo;
        }
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let params = Object.assign({pageSize: this.pageSize, pageNo: this.currentPage}, filter,code);

        let http = this.post ? this.$http.post(this.url, params) : this.$http.get(this.url, params);
        http.then(data => {
          this.tableData = data.records;
          this.total = data.total;
          if (this.total === 0) {
            this.status = "empty";
          } else {
            this.status = "finish";
          }
        }).catch(() => {
          this.status = "error";
        });
      },
      handleCurrentChange(pageNo) {
        this.getList(pageNo);
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.getList(1);
      },
      handleSearchClick() {
        this.getList(1);
        this.$emit("getStatistics");
      },
      handleClearClick() {
        this.$emit("update:filter", JSON.parse(this.filterCopy));
        this.$emit("clear");
        this.$nextTick(() => {
          this.getList(1);
        });
      },
      setCurrentRow(row) {
        this.$refs.elTable.setCurrentRow(row);
      },
      onCheckedChange(val, index) {
        this.currentRowIndex = val ? index : -1;
        this.$emit("radio-change", this.tableData[this.currentRowIndex], val);
      },
      getCurrentRow() {
        return this.tableData[this.currentRowIndex];
      },
    },
  };
</script>

<style lang="scss">
.vm-page-table {

  .tools-bar {
    margin-bottom: 10px;
  }

  .ad-search {
    overflow: auto;
    width: auto;
    border: 1px solid #EBEEF5;
    margin-bottom: 10px;
    background-color: #f4f7fa;

    .ad-search_content {
      position: relative;
      display: inline-block;
      padding: 8px 173px 8px 10px;

      .ad-search-btn {
        position: absolute;
        right: 20px;
        bottom: 11px;
      }
    }
  }

  .page {
    float: right;
  }

  .loading {
    position: absolute;
    margin-left: -40px;
    left: 50%;
    top: 25%;
    z-index: 1000;
  }
}
</style>
