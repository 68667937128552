<template>
  <div>
    <el-select
      class="item"
      :value="provinceCode?provinceCode:''"
      :placeholder="$l('address.province','省份')"
      clearable
      :disabled="provinceDisable"
      @change="onProvinceChange">
      <el-option
        v-for="item in provinces"
        :key="item.id"
        :label="item.label"
        :value="item.id"></el-option>
    </el-select>
    <el-select
      class="item"
      style="margin: 0 5px"
      :value="cityCode?cityCode:''"
      :placeholder="$l('address.city','城市')"
      clearable
      :disabled="cityDisable"
      @change="onCityChange">
      <el-option
        v-for="item in cities"
        :key="item.id"
        :label="item.label"
        :value="item.id"></el-option>
    </el-select>
    <el-select
      class="item"
      :value="areaCode?areaCode:''"
      :placeholder="$l('address.district','区县')"
      clearable
      :disabled="directDisable"
      @change="onAreaChange">
      <el-option
        v-for="item in areas"
        :key="item.id"
        :label="item.label"
        :value="item.id"></el-option>
    </el-select>
  </div>
</template>

<script>
  import {mutations, store} from "@/store/store";

  export default {
    components: {},
    props: {
      provinceCode: String,
      cityCode: String,
      areaCode: String,
      directName:String,
      cityDisable:Boolean,
      provinceDisable:Boolean,
      directDisable:Boolean,
    },
    data() {
      return {};
    },
    computed: {
      provinces() {
        return store.district;
      },
      cities() {
        return this.$oc(() => {
          let item = this.provinces.find(item => {
            return item.id === this.provinceCode;
          });
          return item.children;
        });
      },
      areas() {
        return this.$oc(() => {
          let item = this.cities.find(item => {
            return item.id === this.cityCode;
          });
          return item.children;
        });
      },
    },
    mounted() {
      mutations.getDistrict().then(() => {
        console.log("----获取地区成功----");
      });
    },
    methods: {
      onProvinceChange(provinceCode) {
        this.provinces.forEach(item=>{
          if (item.id ===provinceCode) {
            this.$emit("update:directName", item.label);
          }
        });


        this.$emit("update:provinceCode", provinceCode);
        this.$emit("update:cityCode", "");
        this.$emit("update:areaCode", "");
      },
      onCityChange(cityCode) {
        this.cities.forEach(item=>{

          if (item.id===cityCode) {
            this.$emit("update:directName", item.label);
          }
        });
        this.$emit("update:cityCode", cityCode);
        this.$emit("update:areaCode", "");
      },
      onAreaChange(areaCode) {
        this.areas.forEach(item=>{
          if (item.id===areaCode) {
            this.$emit("update:directName", item.label);
          }
        });

        this.$emit("update:areaCode", areaCode);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .item {
    width: 150px;
  }
</style>
