import Vue from "vue";
import App from "./App.vue";
import i18n from "@/i18n";
import router from "@/router/router";
import dataV from "@jiaminghi/data-view";
import "./plugins/element.js";
import "./plugins/myPlugin.js";
import "./plugins/component.js";
import dayjs from "dayjs";
import "normalize.css";
import "./style/main.scss";
import * as echarts from "echarts";
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/chart-bar.js";
import "vue-awesome/icons/chart-area.js";
import "vue-awesome/icons/chart-pie.js";
import "vue-awesome/icons/chart-line.js";
import "vue-awesome/icons/align-left.js";
import "./assets/scss/style.scss";
import scroll from "vue-seamless-scroll";
import moment from "moment";//导入文件
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
// 水印
import watermark from "./util/watermark.js";
Vue.prototype.$watermark = watermark;
Vue.use(ViewUI);
Vue.use(scroll);
import VueContextMenu from "vue-contextmenu";
Vue.use(VueContextMenu);
Vue.prototype.$moment = moment;//赋值使用

Vue.prototype.$echarts = echarts;//echarts
Vue.config.productionTip = false;
Vue.prototype.dayjs = dayjs;
Vue.component("icon", Icon);

Vue.use(dataV);
import VueIntro from "vue-introjs";
import "intro.js/introjs.css";
Vue.use(VueIntro);

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount("#app");
