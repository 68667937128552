<template>
  <el-input
    :clearable="clearable"
    :value="value"
    :maxlength="maxlength"
    class="vm-select-input"
    :placeholder="disabled?'':placeholder"
    :disabled="disabled"
    @input="onChange"
    @clear="clear">
    <div v-if="!disabled" slot="append" class="iconWrapper" @click="onSelect">
      <i class="el-icon-search"></i>
    </div>
  </el-input>
</template>

<script>
  export default {
    props: {
      value: [String, Number],
      clearable: Boolean,
      disabled: Boolean,
      maxlength:{
        type:[String,Number],
        default() {
          return 0;
        },
      },
      placeholder: {
        type: String,
        default() {
          return this.$l("common.select", "请选择");
        },
      },
    },
    data() {
      return {
        values:"",
      };
    },
    watch:{
      disabled:{
        handler(params) {
          console.log(37,params);
          this.disabled=params;
        },
        deep:true,
        immediate:true,
      },
    },
    methods: {
      onSelect() {
        this.$emit("select");
      },
      onChange(val) {
        console.log(35,val);
        this.$emit("input", val);
      },
      clear() {
        this.$emit("clear");
      },
    },
  };
</script>

<style lang="scss">
.vm-select-input {
  .iconWrapper {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: -20px -20px;
    height: 40px
  }
}

</style>
