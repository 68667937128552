import Vue from "vue";
import VmTable from "@/components/VmTable";
import VmSearch from "@/components/VmSearch";
import VmSelect from "@/components/VmSelect";
import VmSelectInput from "@/components/VmSelectInput";
import VmDictSelect from "@/components/VmDictSelect";
import VmDistrictSelect from "@/components/VmDistrictSelect";
import VmDistrictCitySelect from "@/components/VmDistrictCitySelect";
import VmDragUploadFile from "@/components/VmDragUploadFile";

Vue.component("VmTable", VmTable);
Vue.component("VmSearch", VmSearch);
Vue.component("VmSelect", VmSelect);
Vue.component("VmSelectInput", VmSelectInput);
Vue.component("VmDictSelect", VmDictSelect);
Vue.component("VmDistrictSelect", VmDistrictSelect);
Vue.component("VmDistrictCitySelect", VmDistrictCitySelect);
Vue.component("VmDragUploadFile", VmDragUploadFile);
