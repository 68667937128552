import {myI18n} from "@/i18n";

//维保类型
const wbType = ["1:" + myI18n("wb.typeRoutine", "日常维保"), "2:" + myI18n("wb.typeComponent", "部件更换")];
//维保类型
const wbStatus = ["1:" + myI18n("wb.intend", "计划中"), "2:" + myI18n("wb.completed", "已完成")];
let exportObj = {
  wbType,
  wbStatus,
};

//------------------------------以下部分请勿改动------------------------------
//转化为object和options
//$options用于获取el-select可用的格式,$object用于根据key取中文描述
for (let key in exportObj) {
  let value = exportObj[key];
  if (Array.isArray(value)) {
    let options = [];
    let object = {};
    value.forEach(item => {
      let itemArr = item.split(":");
      let option = {
        value: Number(itemArr[0]),
        label: itemArr[1],
      };
      object[option.value] = option.label;
      options.push(option);
    });
    value.$options = options;
    value.$object = object;
  }
}
export default exportObj;
