const USER_INFO = "hold_user_info";
const storage = window.localStorage;
let userInfo = null;
export default {
  login(obj) {
    userInfo = obj;
    if (obj.authorities) {
      storage.setItem("authorities", JSON.stringify(obj.authorities));
    }
    storage.setItem(USER_INFO, JSON.stringify(obj));
  },
  logout() {
    userInfo = null;
    storage.removeItem(USER_INFO);
  },
  isLogin() {
    return !!this.getToken();
  },
  getUserInfo() {
    try {
      if (!userInfo) {
        userInfo = JSON.parse(storage.getItem(USER_INFO));
      }
    } catch (err) {
      console.log(err);
    }
    return userInfo || {};
  },
  getToken() {
    return this.getUserInfo().token;
  },
  getUsername() {
    return this.getUserInfo().customer?`${this.getUserInfo().customer.name}(${this.getUserInfo().customer.phone})`:`${this.getUserInfo().employee.name}(${this.getUserInfo().employee.phone})`;
  },
  getCityCode() {
    return this.getUserInfo().employee?.cityCode;
  },
  getCompanyCode() {
    return this.getUserInfo().employee?.companyId;
  },
  getUserId() {
    return this.getUserInfo().userId;
  },
  getUserType() {
    return this.getUserInfo().userType;
  },
  getUserRoleTypes() {
    console.log(this.getUserInfo());
    return this.getUserInfo().user.roleTypes;
  },
  isAdmin() {
    return this.getUserType() === "superAdmin";
  },
  hasAuth(authName) {
    //超级管理员默认有所有权限
    return this.isAdmin() || (this.getUserInfo().permissions || []).indexOf(authName) > -1;
  },
  isAuth(name,path,type) {
    console.log(name,path,type);
    const arr  =   JSON.parse(storage.getItem("authorities")) ;
    const url = this.findUlr(arr,path);
    console.log(url,52);
  },
  findUlr(arr,path) {
    arr.forEach(item=>{
      if (item.other.uniqueKey === path) {
        return path;
      }else if (item.children) {
        this.findUlr(item.children,path);
      }else {
        return  false;
      }
    });
  },
};
