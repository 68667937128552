import axios from "axios";
import auth from "@/util/loginUtil";
import {toLogin} from "@/router/router";
import {Notification} from "element-ui";
import i18n, {myI18n} from "@/i18n";

// axios 配置
axios.defaults.timeout = 3000000;
axios.defaults.baseURL = window.config.serverUrl;
// axios.defaults.picture = window.config.pictureUrl;
// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers["Authorization"] = auth.getToken();
    config.headers["Pragma"] = "no-cache"; // 防止ie下get方法缓存
    config.headers["Accept-Language"] = i18n.locale; // 国际化
      // console.log(config);

      if (config.url.indexOf("/approved")>-1||config.url.indexOf("/re-upload")>-1||config.url.indexOf("/direct-upload")>-1) {
          config.baseURL = window.config.pictureUrl;
      }else if (config.url.indexOf("getMaintenanceInfoByMainId")>-1) {
          config.baseURL = window.config.reuploadUrl;
      }
      console.log(config,22);

    return config;
  },
  err => {
    return Promise.reject(err);
  },
);

// http response 拦截器
axios.interceptors.response.use(
  response => {
    // console.log(response);
    if (response.request.responseType === "blob") {
      return Promise.resolve(response.data);
    }
    return response.data;
  },
  error => {
    console.log("-------------接口请求错误信息start-------------");
    console.log(error);
    if (error.response) {
      if (error.response.status === 401) {
        toLogin();
      }
      Notification.error({title: myI18n("common.tip", "提示"), message: error.response.data.message});
    } else if (error.request) {
      console.log(error.request);
      Notification.error({title: myI18n("common.tip", "提示"), message: "连接服务器失败"});
    } else {
      console.log("Error", error.message);
      Notification.error({title: myI18n("common.tip", "提示"), message: "请求服务器出错"});
    }
    console.log("-------------接口请求错误信息end-------------");
    return Promise.reject(error);
  },
);

export default axios;
