<!--可输入或者选择的input组件-->
<template>
  <el-select 
    v-model="internalValue" 
    :multiple="multiple"
    :disabled="disabled"
    placeholder="请选择" 
    clearable>
    <el-option
      v-for="item in options"
      :key="item.code"
      :label="item.desc"
      :value="item.code">
    </el-option>
  </el-select>
</template>
<script>
  import {mutations} from "@/store/store";

  export default {
    props: {
      value: [String,Array],
      disabled:Boolean,
      type: {
        type: String,
        required: true,
      },
      multiple: String,
    },
    data() {
      return {
        options: {},
      };
    },
    computed: {
      internalValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit("input", v);
        },
      },
    },
    mounted() {
      this.getOptions();
    },
    methods: {
      getOptions() {
        mutations.getDicts().then(result => {
          // let str ="";
          // result[this.type].forEach(item=>str+=item.desc+",");
          // console.log(str,53,this.type);
          this.options = this.$oc(() => result[this.type], {});
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
