
import auth from "@/util/loginUtil";
import {Message} from "element-ui";
const XLSX = require("xlsx");

// 导出Excel
//toolFn.ts文件

/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay) {
    var timer;
    return function() {
        var context = this;
        var args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function() {
            fn.apply(context, args);
        }, delay);
    };
}

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
    if (!time) return "";
    else {
        const date = new Date(time);
        const o = {
            "M+": date.getMonth() + 1,
            "d+": date.getDate(),
            "H+": date.getHours(),
            "m+": date.getMinutes(),
            "s+": date.getSeconds(),
            "q+": Math.floor((date.getMonth() + 3) / 3),
            S: date.getMilliseconds(),
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                (date.getFullYear() + "").substr(4 - RegExp.$1.length),
            );
        for (const k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1
                        ? o[k]
                        : ("00" + o[k]).substr(("" + o[k]).length),
                );
            }
        }
        return fmt;
    }
}
export function getWidth(children,width=0) {
    let maxWidth=width;

    for (let i = 0; i < children.length; i++) {
        if (children[i].children) {
            maxWidth= getWidth(children[i].children,maxWidth);
        }else {
            if (children[i].label.length>maxWidth) {
                maxWidth=children[i].label.length;
            }
        }
    }
    return maxWidth;
}
export function downloadFiles(url, name, cb) {
    // url 为需要访问的地址，name为下载文件的名字
    // 本方法用于需要携带token下载文件的接口
    // 原生ajax 访问 携带token ， a 标签下载
    const xhr = new XMLHttpRequest();
    xhr.open("GET", window.config.serverUrl+url, true);
    //设置请求头参数的方式,如果没有可忽略此行代码
    xhr.setRequestHeader("Authorization", `${auth.getToken()}`);
    //设置响应类型为 blob   xhr.open必须为 异步
    xhr.responseType = "blob";
    //关键部分\
    Message.success("准备下载");
    xhr.send();
    xhr.onload = function() {
        //如果请求执行成功
        if (this.status === 200) {
            const blob = this.response;
            const a = document.createElement("a");
            // blob.type = "application/octet-stream";
            const pdfUrl = window.URL.createObjectURL(blob);
            a.href = pdfUrl;
            a.download = name; //设置下载文件的名字
            document.body.appendChild(a); // 火狐浏览器 必须把元素插入body中
            a.click();
            document.body.removeChild(a);
            //释放之前创建的URL对象
            window.URL.revokeObjectURL(url);
            Message.success("下载成功");
            cb({
                res: {
                    message: "成功",
                },
            });
        }
        if (this.status.toString().startsWith("4")) {
            cb({
                res: {
                    message: "未找到文件",
                },
            });
            Message.error("未找到文件");
        }
        if (this.status.toString().startsWith("5")) {
            cb({
                res: {
                    message: "服务器错误,请联系开发人员",
                },
            });
            Message.error("服务器错误,请联系开发人员");
        }

    };
}

/**
 * 导出excel
 * @param title 表头
 * @param data 内容
 * @param name xlsx表名
 */
export function export2Excel(title,data,name) {
    const option = ["online","elevatorOnline","iotOnline","elevatorStopped","elevatorFalling","peopleTrapped", "isRepeat","completed"];//用于配置true和false的数据
    let docName = name;
    if (name.indexOf(".xlsx")===-1) {
        docName = docName+".xlsx";
    }
    const  list = [];
    let arr =[];
    //读取表头并放入list中
    // eslint-disable-next-line no-unused-vars
    for (let [key,value] of Object.entries(title)) {
        arr.push(value);
    }
    list.push(arr);
    data.forEach((item)=>{
        arr=[];
        if (item) {
            // eslint-disable-next-line no-unused-vars
            for (let [key,value] of Object.entries(title)) {
                if (option.includes(key)) {
                    arr.push(item[key]?"是":"否");
                }else {
                    arr.push(item[key]);
                }

            }
        }

        list.push(arr);
    });
    let aoa = list;
    let work  = XLSX.utils.aoa_to_sheet(aoa);
    let workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook,work,"sheet1");
    XLSX.writeFile(workbook,docName);
}

export function export2Excels(title,data,name,sheetUrl,keyWord,sheetTitle) {
    const option = ["online"];//用于配置true和false的数据
    let docName = name;
    if (name.indexOf(".xlsx")===-1) {
        docName = docName+".xlsx";
    }
    const  list = [];
    let arr =[];
    //读取表头并放入list中
    // eslint-disable-next-line no-unused-vars
    for (let [key,value] of Object.entries(title)) {
        arr.push(value);
    }
    list.push(arr);
    data.forEach(item=>{
        arr=[];
        // eslint-disable-next-line no-unused-vars
        for (let [key,value] of Object.entries(title)) {
            if (option.includes(key)) {
                arr.push(item[key]?"是":"否");
            }else {
                arr.push(item[key]);
            }

        }
        list.push(arr);
    });
    let aoa = list;
    let work  = XLSX.utils.aoa_to_sheet(aoa);
    let workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook,work,"sheet1");
    console.log(sheetTitle,sheetUrl,keyWord);
    // data.forEach(item=>{
    //     if (item )
    // })
    XLSX.writeFile(workbook,docName);
}
