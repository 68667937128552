import Vue from "vue";
import axios from "./http/axios";
import http from "./http/http";
import drag from "./directive/el-dialog-drag";
import bus from "./bus";
import {Message} from "element-ui";
import loginUtil from "@/util/loginUtil";
import constant from "@/util/constant";
import rule from "@/util/rule";
import commonUtil from "@/util/commonUtil";
import {myI18n} from "@/i18n";
import Print from "vue-print-nb";
import auth from "./auth";

Plugin.install = function(Vue) {
  Vue.directive("el-drag-dialog", drag);
  //event bus
  Vue.prototype.$bus = bus;
  // 网络请求
  Vue.prototype.$http = http;
  Vue.prototype.$axios = axios;
  Vue.prototype.$util = commonUtil;
  Vue.prototype.$constant = constant;
  Vue.prototype.$rule = rule;
  Vue.prototype.$auth = auth;
  Vue.prototype.$assign = function(target, source) {
    if (target && source) {
      Object.keys(target).map(key => {
        if (key in source) {
          if (Array.isArray(target[key])) {
            target[key] = source[key] || [];
          } else {
            target[key] = source[key];
          }
        }
      });
    }
  };
  //模拟Optional Chaining
  Vue.prototype.$oc = function(fn, defaultVal) {
    try {
      return fn();
    } catch (e) {
      return defaultVal;
    }
  };
  //重置表单
  Vue.prototype.$reset = function(formRef, ...excludeFields) {
    let obj1 = this.$data;
    let obj2 = this.$options.data.call(this);
    if (!excludeFields || excludeFields.length === 0) {
      excludeFields = ["ruleValidate"];
    }
    for (let attrName in obj1) {
      if (excludeFields && excludeFields.includes(attrName)) {
        continue;
      }
      obj1[attrName] = obj2[attrName];
    }
    let ref = this.$refs[formRef || "form"];
    if (ref) {
      setTimeout(() => {
        ref.clearValidate();
      }, 500);
    }
  };
  //判断是否有权限
  Vue.prototype.$hasAuth = function(authName) {
    return loginUtil.hasAuth(authName);
  };
  Vue.prototype.$l = myI18n;
  //全局异常处理
  Vue.config.errorHandler = function(err, vm, info) {
    console.error(err, vm, info);
    Message.error(err);
  };
};
Vue.use(Plugin);
Vue.use(Print);

export default Plugin;
