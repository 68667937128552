<template>
  <el-upload
    v-loading="loading"
    class="upload"
    multiple
    :drag="true"
    :action="uploadUrl"
    :show-file-list="false"
    :accept="accept"
    :file-list="fileList"
    :on-success="handleSuccess"
    :on-error="handleError"
    :on-remove="handleRemove"
    :headers="headers"
    style="margin-bottom: 4px"
    :before-upload="beforeUpload">
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    <div slot="tip" class="el-upload__tip">{{name}}</div>
    <div v-if="downloadUrl" slot="tip" class="el-upload__tip"><el-button type="primary" @click="downFile()">下载模板</el-button></div>
    <div v-if="downloadUrl===''" slot="tip" class="el-upload__tip"><el-button disabled>暂无模板</el-button></div>
  </el-upload>
</template>
<script>
  //const fileServer = "http://dfs.cricbigdata.com/view/";
  import auth from "@/util/loginUtil";
  import {downloadFiles} from "@/util";

  export default {
    props: {
      fileList: [Array],
      action:{
        type:String,
      },
      accept:{
        type:String,
      },
      name:{
        type:String,
        default:"上传文件",
      },
      downloadName:{
        type:String,
        default:"上传文件",
      },
      url:{
        type:String,
        default:"",
      },
    },
    data() {
      return {
        uploadUrl:"",
        downloadUrl:"",
        loading:false,
        headers:{
          "Authorization": `${auth.getToken()}`,
        },
      };
    },
    watch:{
      action:{
        handler(pramas) {

          this.uploadUrl=window.config.serverUrl+pramas;

          console.log(this.uploadUrl,41);
        },
        deep:true,
        immediate:true,
      },
      url:{
        handler(pramas) {
          this.downloadUrl=pramas;
        },
        deep:true,
        immediate:true,
      },
    },
    methods: {
      async downFile() {
        this.loading=true;
        downloadFiles(this.downloadUrl,this.downloadName,()=> {
          this.loading=false;
        });
      },
      handleSuccess(res, file, fileList) {
        if (res.success) {
          this.$message.success(res.message);
        }else {
          this.$message.error(res.message);
        }
        this.loading = false;
        // this.$message.success("导入成功");
        this.$emit("save-success");
        this.onFileListChange(fileList);
      },
      handleError() {
        this.loading = false;
        this.$message.error("上传失败");
      },
      handlePreview(file) {
        window.open(file.url);
      },
      handleRemove(file, fileList) {
        this.onFileListChange(fileList);
      },
      beforeUpload(file) {
        // let fileName = file.name;
        // let pos = fileName.lastIndexOf(".");
        // let lastName = fileName.substring(pos, fileName.length);
        let size = file.size / 1024 / 1024;
        // if (![".doc", ".docx", ".pdf", ".pptx"].includes(lastName.toLowerCase())) {
        //   this.$message.error("不支持的文件类型");
        //   return false;
        // }
        if (size > 20) {
          this.$message.error("文件不能超过20MB!");
          return false;
        }
        this.loading=true;
        //this.$message.success("导入成功");
        return true;
      },
      onFileListChange() {
        // fileList.forEach(item => {
        // item.url = item.url || (fileServer + item.response.file.sFile);
        //});
        //this.$emit("update:fileList", fileList);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .file {
    margin-left: 10px;
    font-size: 14px;
  }
  .upload{
    display: inline-block;
    margin-left:  5px;
  }
  .el-upload__tip{
    text-align: center;
  }
</style>
