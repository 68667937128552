<template>
  <el-select v-model="internalValue" clearable :placeholder="$l('common.select','请选择')">
    <el-option
      v-for="item in items"
      :key="item.value"
      :value="item.value"
      :label="item.label">
    </el-option>
  </el-select>
</template>

<script>

  export default {
    props: {
      value: [Number, String],
      name: {
        type: String,
        required: true,
      },
      showValues: {
        type: Array,
      },
    },
    data() {
      return {
        title: "",
        content: "",
      };
    },
    computed: {
      internalValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit("input", v);
        },
      },
      items() {
        if (this.showValues) {
          return this.$constant[this.name].$options.filter(item => {
            return this.showValues.indexOf(item.value) > -1;
          });
        } else {
          return this.$constant[this.name].$options;
        }
      },
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
</style>
